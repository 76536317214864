import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import GuestRoute from "./guestRoute";
import ProtectedRoute from "./protectedRoute";

const Header = lazy(() => import("./components/header"));
const Home = lazy(() => import("./components/home"));
const Login = lazy(() => import("./components/login"));
const PdfUpload = lazy(() => import("./components/pdfUpload"));
const ExcelUpload = lazy(() => import("./components/excelUpload"));

const Layout = ({ children }) => {
  return <>
    <Header/>
    {children}
  </>;
};

export default function App() {
  return (
    <Suspense fallback="Loading...">
      <div>
        <Router>
          <Switch>
              <GuestRoute exact path="/login" component={Login} />
              <GuestRoute exact path="/" component={Home} />
            <Layout>
              <ProtectedRoute exact path="/pdf" component={PdfUpload} />
              <ProtectedRoute exact path="/excel" component={ExcelUpload} />
            </Layout>
          </Switch>
        </Router>
      </div>
    </Suspense>
  );
}
